import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6054c32a = () => interopDefault(import('../src/pages/accounting-books/index.vue' /* webpackChunkName: "pages/accounting-books/index" */))
const _20de8d4f = () => interopDefault(import('../src/pages/accounting-rule.vue' /* webpackChunkName: "pages/accounting-rule" */))
const _218569e8 = () => interopDefault(import('../src/pages/activate.vue' /* webpackChunkName: "pages/activate" */))
const _90203fee = () => interopDefault(import('../src/pages/awa/index.vue' /* webpackChunkName: "pages/awa/index" */))
const _11d60b47 = () => interopDefault(import('../src/pages/beginning-balance.vue' /* webpackChunkName: "pages/beginning-balance" */))
const _221deb4a = () => interopDefault(import('../src/pages/carryover.vue' /* webpackChunkName: "pages/carryover" */))
const _1f4fbe28 = () => interopDefault(import('../src/pages/change-card.vue' /* webpackChunkName: "pages/change-card" */))
const _6484dff2 = () => interopDefault(import('../src/pages/coincheck.vue' /* webpackChunkName: "pages/coincheck" */))
const _2dc646dd = () => interopDefault(import('../src/pages/coins.vue' /* webpackChunkName: "pages/coins" */))
const _fbf5827a = () => interopDefault(import('../src/pages/corporate/index.vue' /* webpackChunkName: "pages/corporate/index" */))
const _ab031a5c = () => interopDefault(import('../src/pages/cpta/index.vue' /* webpackChunkName: "pages/cpta/index" */))
const _79d2bd0c = () => interopDefault(import('../src/pages/daily-rate/index.vue' /* webpackChunkName: "pages/daily-rate/index" */))
const _534fb40a = () => interopDefault(import('../src/pages/email-confirmation.vue' /* webpackChunkName: "pages/email-confirmation" */))
const _e9bfccee = () => interopDefault(import('../src/pages/guardian/index.vue' /* webpackChunkName: "pages/guardian/index" */))
const _50d9a446 = () => interopDefault(import('../src/pages/import/index.vue' /* webpackChunkName: "pages/import/index" */))
const _3c477cd6 = () => interopDefault(import('../src/pages/inspectation.vue' /* webpackChunkName: "pages/inspectation" */))
const _43378bd5 = () => interopDefault(import('../src/pages/journal/index.vue' /* webpackChunkName: "pages/journal/index" */))
const _f00509a0 = () => interopDefault(import('../src/pages/journal-master/index.vue' /* webpackChunkName: "pages/journal-master/index" */))
const _0355a648 = () => interopDefault(import('../src/pages/news.vue' /* webpackChunkName: "pages/news" */))
const _2d85d6ba = () => interopDefault(import('../src/pages/pending-trades.vue' /* webpackChunkName: "pages/pending-trades" */))
const _2e60e9bd = () => interopDefault(import('../src/pages/pending-trades/index.vue' /* webpackChunkName: "pages/pending-trades/index" */))
const _74f5f34e = () => interopDefault(import('../src/pages/pending-trades/accounting-transfer.vue' /* webpackChunkName: "pages/pending-trades/accounting-transfer" */))
const _12798e5a = () => interopDefault(import('../src/pages/pending-trades/defis.vue' /* webpackChunkName: "pages/pending-trades/defis" */))
const _ed4d2fee = () => interopDefault(import('../src/pages/pending-trades/tx-confirmation.vue' /* webpackChunkName: "pages/pending-trades/tx-confirmation" */))
const _f0b488ba = () => interopDefault(import('../src/pages/portfolio.vue' /* webpackChunkName: "pages/portfolio" */))
const _231dcea3 = () => interopDefault(import('../src/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _82c763dc = () => interopDefault(import('../src/pages/register-kanjobugyo.vue' /* webpackChunkName: "pages/register-kanjobugyo" */))
const _005ac9df = () => interopDefault(import('../src/pages/regulation.vue' /* webpackChunkName: "pages/regulation" */))
const _66e07de7 = () => interopDefault(import('../src/pages/reminder.vue' /* webpackChunkName: "pages/reminder" */))
const _a954278a = () => interopDefault(import('../src/pages/resetpass.vue' /* webpackChunkName: "pages/resetpass" */))
const _eded1a82 = () => interopDefault(import('../src/pages/sct.vue' /* webpackChunkName: "pages/sct" */))
const _78916857 = () => interopDefault(import('../src/pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _e5b08266 = () => interopDefault(import('../src/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _5e513e5e = () => interopDefault(import('../src/pages/subscriptions.vue' /* webpackChunkName: "pages/subscriptions" */))
const _2d924694 = () => interopDefault(import('../src/pages/subscriptions/index.vue' /* webpackChunkName: "pages/subscriptions/index" */))
const _3c0819a0 = () => interopDefault(import('../src/pages/subscriptions/apply.vue' /* webpackChunkName: "pages/subscriptions/apply" */))
const _ecd510f0 = () => interopDefault(import('../src/pages/subscriptions/auto-renewal.vue' /* webpackChunkName: "pages/subscriptions/auto-renewal" */))
const _693f15b0 = () => interopDefault(import('../src/pages/subscriptions/cancel.vue' /* webpackChunkName: "pages/subscriptions/cancel" */))
const _03e6d6db = () => interopDefault(import('../src/pages/subscriptions/payments.vue' /* webpackChunkName: "pages/subscriptions/payments" */))
const _515e5e2c = () => interopDefault(import('../src/pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _19c1454d = () => interopDefault(import('../src/pages/trade-download-history.vue' /* webpackChunkName: "pages/trade-download-history" */))
const _08ab1ad0 = () => interopDefault(import('../src/pages/trade-summary.vue' /* webpackChunkName: "pages/trade-summary" */))
const _1c7075c4 = () => interopDefault(import('../src/pages/trades.vue' /* webpackChunkName: "pages/trades" */))
const _baebfc72 = () => interopDefault(import('../src/pages/trades-setting.vue' /* webpackChunkName: "pages/trades-setting" */))
const _13220fec = () => interopDefault(import('../src/pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _5e37e386 = () => interopDefault(import('../src/pages/user-setting/index.vue' /* webpackChunkName: "pages/user-setting/index" */))
const _982e2568 = () => interopDefault(import('../src/pages/accounting-books/journal-list.vue' /* webpackChunkName: "pages/accounting-books/journal-list" */))
const _a3e90a84 = () => interopDefault(import('../src/pages/awa/contact-sent.vue' /* webpackChunkName: "pages/awa/contact-sent" */))
const _48ca5a84 = () => interopDefault(import('../src/pages/awa/inquiry.vue' /* webpackChunkName: "pages/awa/inquiry" */))
const _76494677 = () => interopDefault(import('../src/pages/corporate/signup.vue' /* webpackChunkName: "pages/corporate/signup" */))
const _4099e9cf = () => interopDefault(import('../src/pages/cpta/cpta-office.vue' /* webpackChunkName: "pages/cpta/cpta-office" */))
const _26dca8d0 = () => interopDefault(import('../src/pages/cpta/customers/index.vue' /* webpackChunkName: "pages/cpta/customers/index" */))
const _5cf69448 = () => interopDefault(import('../src/pages/cpta/signup.vue' /* webpackChunkName: "pages/cpta/signup" */))
const _5b8d44fa = () => interopDefault(import('../src/pages/guardian/regulation.vue' /* webpackChunkName: "pages/guardian/regulation" */))
const _62ddbab0 = () => interopDefault(import('../src/pages/import/api/index.vue' /* webpackChunkName: "pages/import/api/index" */))
const _5878c441 = () => interopDefault(import('../src/pages/import/file.vue' /* webpackChunkName: "pages/import/file" */))
const _33d153f8 = () => interopDefault(import('../src/pages/import/file/index.vue' /* webpackChunkName: "pages/import/file/index" */))
const _808170dc = () => interopDefault(import('../src/pages/import/file/blockchain.vue' /* webpackChunkName: "pages/import/file/blockchain" */))
const _63ef4ed6 = () => interopDefault(import('../src/pages/import/file/blockchain/index.vue' /* webpackChunkName: "pages/import/file/blockchain/index" */))
const _0a1d8bf7 = () => interopDefault(import('../src/pages/import/file/blockchain/address.vue' /* webpackChunkName: "pages/import/file/blockchain/address" */))
const _0dd76289 = () => interopDefault(import('../src/pages/import/file/blockchain/file.vue' /* webpackChunkName: "pages/import/file/blockchain/file" */))
const _0cf5a2ae = () => interopDefault(import('../src/pages/import/file/common.vue' /* webpackChunkName: "pages/import/file/common" */))
const _6f0cdce2 = () => interopDefault(import('../src/pages/import/file/exchangers.vue' /* webpackChunkName: "pages/import/file/exchangers" */))
const _7d143b74 = () => interopDefault(import('../src/pages/import/file/history.vue' /* webpackChunkName: "pages/import/file/history" */))
const _3e75c76e = () => interopDefault(import('../src/pages/import/file/history/index.vue' /* webpackChunkName: "pages/import/file/history/index" */))
const _8308b726 = () => interopDefault(import('../src/pages/import/file/history/blockchain.vue' /* webpackChunkName: "pages/import/file/history/blockchain" */))
const _9fb2ad4a = () => interopDefault(import('../src/pages/import/file/unimported.vue' /* webpackChunkName: "pages/import/file/unimported" */))
const _f79a9610 = () => interopDefault(import('../src/pages/journal-master/accounts.vue' /* webpackChunkName: "pages/journal-master/accounts" */))
const _14d1da00 = () => interopDefault(import('../src/pages/journal-master/addresses.vue' /* webpackChunkName: "pages/journal-master/addresses" */))
const _641d33e6 = () => interopDefault(import('../src/pages/journal-master/clients.vue' /* webpackChunkName: "pages/journal-master/clients" */))
const _00472482 = () => interopDefault(import('../src/pages/journal-master/departments.vue' /* webpackChunkName: "pages/journal-master/departments" */))
const _398b131e = () => interopDefault(import('../src/pages/journal-master/items.vue' /* webpackChunkName: "pages/journal-master/items" */))
const _3c7900d5 = () => interopDefault(import('../src/pages/journal-master/rules.vue' /* webpackChunkName: "pages/journal-master/rules" */))
const _5514d398 = () => interopDefault(import('../src/pages/journal-master/rules/index.vue' /* webpackChunkName: "pages/journal-master/rules/index" */))
const _7135267e = () => interopDefault(import('../src/pages/journal-master/rules/blockchain.vue' /* webpackChunkName: "pages/journal-master/rules/blockchain" */))
const _2082bbce = () => interopDefault(import('../src/pages/journal-master/rules/exchangers.vue' /* webpackChunkName: "pages/journal-master/rules/exchangers" */))
const _486e3ce2 = () => interopDefault(import('../src/pages/journal-master/tax-classes.vue' /* webpackChunkName: "pages/journal-master/tax-classes" */))
const _6785bc89 = () => interopDefault(import('../src/pages/journal-master/valuation-rules.vue' /* webpackChunkName: "pages/journal-master/valuation-rules" */))
const _664af84c = () => interopDefault(import('../src/pages/journal-master/valuation-rules/index.vue' /* webpackChunkName: "pages/journal-master/valuation-rules/index" */))
const _535dd24a = () => interopDefault(import('../src/pages/journal-master/valuation-rules/blockchain.vue' /* webpackChunkName: "pages/journal-master/valuation-rules/blockchain" */))
const _02ab679a = () => interopDefault(import('../src/pages/journal-master/valuation-rules/exchangers.vue' /* webpackChunkName: "pages/journal-master/valuation-rules/exchangers" */))
const _589f376f = () => interopDefault(import('../src/pages/journal/beginning-balance.vue' /* webpackChunkName: "pages/journal/beginning-balance" */))
const _c8ea3d04 = () => interopDefault(import('../src/pages/journal/custom.vue' /* webpackChunkName: "pages/journal/custom" */))
const _799bf1bb = () => interopDefault(import('../src/pages/user-setting/change-email.vue' /* webpackChunkName: "pages/user-setting/change-email" */))
const _374df008 = () => interopDefault(import('../src/pages/user-setting/change-mailmagazine.vue' /* webpackChunkName: "pages/user-setting/change-mailmagazine" */))
const _0d217668 = () => interopDefault(import('../src/pages/user-setting/change-password.vue' /* webpackChunkName: "pages/user-setting/change-password" */))
const _cb8cfbf4 = () => interopDefault(import('../src/pages/user-setting/withdraw.vue' /* webpackChunkName: "pages/user-setting/withdraw" */))
const _477a42b6 = () => interopDefault(import('../src/pages/cpta/payment/apply.vue' /* webpackChunkName: "pages/cpta/payment/apply" */))
const _002f50f8 = () => interopDefault(import('../src/pages/cpta/payment/change-card.vue' /* webpackChunkName: "pages/cpta/payment/change-card" */))
const _4b094cac = () => interopDefault(import('../src/pages/cpta/payment/finish-change-card.vue' /* webpackChunkName: "pages/cpta/payment/finish-change-card" */))
const _722f470a = () => interopDefault(import('../src/pages/cpta/payment/finish-withdraw.vue' /* webpackChunkName: "pages/cpta/payment/finish-withdraw" */))
const _fe03de72 = () => interopDefault(import('../src/pages/cpta/payment/info.vue' /* webpackChunkName: "pages/cpta/payment/info" */))
const _f6adfeba = () => interopDefault(import('../src/pages/cpta/payment/withdraw.vue' /* webpackChunkName: "pages/cpta/payment/withdraw" */))
const _17abc2d2 = () => interopDefault(import('../src/pages/awa/download/_docId.vue' /* webpackChunkName: "pages/awa/download/_docId" */))
const _410a1185 = () => interopDefault(import('../src/pages/import/api/_exchangerId.vue' /* webpackChunkName: "pages/import/api/_exchangerId" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accounting-books",
    component: _6054c32a,
    name: "accounting-books"
  }, {
    path: "/accounting-rule",
    component: _20de8d4f,
    name: "accounting-rule"
  }, {
    path: "/activate",
    component: _218569e8,
    name: "activate"
  }, {
    path: "/awa",
    component: _90203fee,
    name: "awa"
  }, {
    path: "/beginning-balance",
    component: _11d60b47,
    name: "beginning-balance"
  }, {
    path: "/carryover",
    component: _221deb4a,
    name: "carryover"
  }, {
    path: "/change-card",
    component: _1f4fbe28,
    name: "change-card"
  }, {
    path: "/coincheck",
    component: _6484dff2,
    name: "coincheck"
  }, {
    path: "/coins",
    component: _2dc646dd,
    name: "coins"
  }, {
    path: "/corporate",
    component: _fbf5827a,
    name: "corporate"
  }, {
    path: "/cpta",
    component: _ab031a5c,
    name: "cpta"
  }, {
    path: "/daily-rate",
    component: _79d2bd0c,
    name: "daily-rate"
  }, {
    path: "/email-confirmation",
    component: _534fb40a,
    name: "email-confirmation"
  }, {
    path: "/guardian",
    component: _e9bfccee,
    name: "guardian"
  }, {
    path: "/import",
    component: _50d9a446,
    name: "import"
  }, {
    path: "/inspectation",
    component: _3c477cd6,
    name: "inspectation"
  }, {
    path: "/journal",
    component: _43378bd5,
    name: "journal"
  }, {
    path: "/journal-master",
    component: _f00509a0,
    name: "journal-master"
  }, {
    path: "/news",
    component: _0355a648,
    name: "news"
  }, {
    path: "/pending-trades",
    component: _2d85d6ba,
    children: [{
      path: "",
      component: _2e60e9bd,
      name: "pending-trades"
    }, {
      path: "accounting-transfer",
      component: _74f5f34e,
      name: "pending-trades-accounting-transfer"
    }, {
      path: "defis",
      component: _12798e5a,
      name: "pending-trades-defis"
    }, {
      path: "tx-confirmation",
      component: _ed4d2fee,
      name: "pending-trades-tx-confirmation"
    }]
  }, {
    path: "/portfolio",
    component: _f0b488ba,
    name: "portfolio"
  }, {
    path: "/privacy",
    component: _231dcea3,
    name: "privacy"
  }, {
    path: "/register-kanjobugyo",
    component: _82c763dc,
    name: "register-kanjobugyo"
  }, {
    path: "/regulation",
    component: _005ac9df,
    name: "regulation"
  }, {
    path: "/reminder",
    component: _66e07de7,
    name: "reminder"
  }, {
    path: "/resetpass",
    component: _a954278a,
    name: "resetpass"
  }, {
    path: "/sct",
    component: _eded1a82,
    name: "sct"
  }, {
    path: "/signin",
    component: _78916857,
    name: "signin"
  }, {
    path: "/signup",
    component: _e5b08266,
    name: "signup"
  }, {
    path: "/subscriptions",
    component: _5e513e5e,
    children: [{
      path: "",
      component: _2d924694,
      name: "subscriptions"
    }, {
      path: "apply",
      component: _3c0819a0,
      name: "subscriptions-apply"
    }, {
      path: "auto-renewal",
      component: _ecd510f0,
      name: "subscriptions-auto-renewal"
    }, {
      path: "cancel",
      component: _693f15b0,
      name: "subscriptions-cancel"
    }, {
      path: "payments",
      component: _03e6d6db,
      name: "subscriptions-payments"
    }]
  }, {
    path: "/thankyou",
    component: _515e5e2c,
    name: "thankyou"
  }, {
    path: "/trade-download-history",
    component: _19c1454d,
    name: "trade-download-history"
  }, {
    path: "/trade-summary",
    component: _08ab1ad0,
    name: "trade-summary"
  }, {
    path: "/trades",
    component: _1c7075c4,
    name: "trades"
  }, {
    path: "/trades-setting",
    component: _baebfc72,
    name: "trades-setting"
  }, {
    path: "/unsubscribe",
    component: _13220fec,
    name: "unsubscribe"
  }, {
    path: "/user-setting",
    component: _5e37e386,
    name: "user-setting"
  }, {
    path: "/accounting-books/journal-list",
    component: _982e2568,
    name: "accounting-books-journal-list"
  }, {
    path: "/awa/contact-sent",
    component: _a3e90a84,
    name: "awa-contact-sent"
  }, {
    path: "/awa/inquiry",
    component: _48ca5a84,
    name: "awa-inquiry"
  }, {
    path: "/corporate/signup",
    component: _76494677,
    name: "corporate-signup"
  }, {
    path: "/cpta/cpta-office",
    component: _4099e9cf,
    name: "cpta-cpta-office"
  }, {
    path: "/cpta/customers",
    component: _26dca8d0,
    name: "cpta-customers"
  }, {
    path: "/cpta/signup",
    component: _5cf69448,
    name: "cpta-signup"
  }, {
    path: "/guardian/regulation",
    component: _5b8d44fa,
    name: "guardian-regulation"
  }, {
    path: "/import/api",
    component: _62ddbab0,
    name: "import-api"
  }, {
    path: "/import/file",
    component: _5878c441,
    children: [{
      path: "",
      component: _33d153f8,
      name: "import-file"
    }, {
      path: "blockchain",
      component: _808170dc,
      children: [{
        path: "",
        component: _63ef4ed6,
        name: "import-file-blockchain"
      }, {
        path: "address",
        component: _0a1d8bf7,
        name: "import-file-blockchain-address"
      }, {
        path: "file",
        component: _0dd76289,
        name: "import-file-blockchain-file"
      }]
    }, {
      path: "common",
      component: _0cf5a2ae,
      name: "import-file-common"
    }, {
      path: "exchangers",
      component: _6f0cdce2,
      name: "import-file-exchangers"
    }, {
      path: "history",
      component: _7d143b74,
      children: [{
        path: "",
        component: _3e75c76e,
        name: "import-file-history"
      }, {
        path: "blockchain",
        component: _8308b726,
        name: "import-file-history-blockchain"
      }]
    }, {
      path: "unimported",
      component: _9fb2ad4a,
      name: "import-file-unimported"
    }]
  }, {
    path: "/journal-master/accounts",
    component: _f79a9610,
    name: "journal-master-accounts"
  }, {
    path: "/journal-master/addresses",
    component: _14d1da00,
    name: "journal-master-addresses"
  }, {
    path: "/journal-master/clients",
    component: _641d33e6,
    name: "journal-master-clients"
  }, {
    path: "/journal-master/departments",
    component: _00472482,
    name: "journal-master-departments"
  }, {
    path: "/journal-master/items",
    component: _398b131e,
    name: "journal-master-items"
  }, {
    path: "/journal-master/rules",
    component: _3c7900d5,
    children: [{
      path: "",
      component: _5514d398,
      name: "journal-master-rules"
    }, {
      path: "blockchain",
      component: _7135267e,
      name: "journal-master-rules-blockchain"
    }, {
      path: "exchangers",
      component: _2082bbce,
      name: "journal-master-rules-exchangers"
    }]
  }, {
    path: "/journal-master/tax-classes",
    component: _486e3ce2,
    name: "journal-master-tax-classes"
  }, {
    path: "/journal-master/valuation-rules",
    component: _6785bc89,
    children: [{
      path: "",
      component: _664af84c,
      name: "journal-master-valuation-rules"
    }, {
      path: "blockchain",
      component: _535dd24a,
      name: "journal-master-valuation-rules-blockchain"
    }, {
      path: "exchangers",
      component: _02ab679a,
      name: "journal-master-valuation-rules-exchangers"
    }]
  }, {
    path: "/journal/beginning-balance",
    component: _589f376f,
    name: "journal-beginning-balance"
  }, {
    path: "/journal/custom",
    component: _c8ea3d04,
    name: "journal-custom"
  }, {
    path: "/user-setting/change-email",
    component: _799bf1bb,
    name: "user-setting-change-email"
  }, {
    path: "/user-setting/change-mailmagazine",
    component: _374df008,
    name: "user-setting-change-mailmagazine"
  }, {
    path: "/user-setting/change-password",
    component: _0d217668,
    name: "user-setting-change-password"
  }, {
    path: "/user-setting/withdraw",
    component: _cb8cfbf4,
    name: "user-setting-withdraw"
  }, {
    path: "/cpta/payment/apply",
    component: _477a42b6,
    name: "cpta-payment-apply"
  }, {
    path: "/cpta/payment/change-card",
    component: _002f50f8,
    name: "cpta-payment-change-card"
  }, {
    path: "/cpta/payment/finish-change-card",
    component: _4b094cac,
    name: "cpta-payment-finish-change-card"
  }, {
    path: "/cpta/payment/finish-withdraw",
    component: _722f470a,
    name: "cpta-payment-finish-withdraw"
  }, {
    path: "/cpta/payment/info",
    component: _fe03de72,
    name: "cpta-payment-info"
  }, {
    path: "/cpta/payment/withdraw",
    component: _f6adfeba,
    name: "cpta-payment-withdraw"
  }, {
    path: "/awa/download/:docId?",
    component: _17abc2d2,
    name: "awa-download-docId"
  }, {
    path: "/import/api/:exchangerId",
    component: _410a1185,
    name: "import-api-exchangerId"
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
