// @ts-check
/** @typedef {import('./tradeValidation').InputRule} InputRule */

/** @type {InputRule} */
export const isString = (val) => typeof val === 'string';

/** @type {InputRule} */
export const isAlphaNum = (val) => isString(val) && /^[a-zA-Z0-9]+$/.test(val);

/**
 * 値が空文字列、null、undefinedでない場合にtrueを返す
 * @param {string | number} val
 * @returns {boolean}
 * */
export const hasValue = (val) => val != null && val !== '';

/**
 * @param {string} str
 * @returns {boolean}
 */
export const isEvmAddress = (str) => typeof str === 'string' && /^0x[0-9a-f]{40}$/i.test(str.trim());
