import { render, staticRenderFns } from "./HelpText.vue?vue&type=template&id=3f7c9bc8&scoped=true&"
import script from "./HelpText.vue?vue&type=script&lang=js&"
export * from "./HelpText.vue?vue&type=script&lang=js&"
import style0 from "./HelpText.vue?vue&type=style&index=0&id=3f7c9bc8&lang=scss&scoped=true&"
import style1 from "./HelpText.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f7c9bc8",
  null
  
)

export default component.exports