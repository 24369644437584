const middleware = {}

middleware['authOnly'] = require('../src/middleware/authOnly.js')
middleware['authOnly'] = middleware['authOnly'].default || middleware['authOnly']

middleware['default'] = require('../src/middleware/default.js')
middleware['default'] = middleware['default'].default || middleware['default']

middleware['unAuthOnly'] = require('../src/middleware/unAuthOnly.js')
middleware['unAuthOnly'] = middleware['unAuthOnly'].default || middleware['unAuthOnly']

export default middleware
