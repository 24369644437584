<template>
    <header class="top-header">
        <nav>
            <a v-if="isTopPage" href="#hero" class="logo-area" role="button">
                <img src="@/assets/images/top/awa/awa_logo.png" alt="AWA" />
            </a>
            <nuxt-link v-else to="/awa" class="logo-area" role="button">
                <img src="@/assets/images/top/awa/awa_logo.png" alt="AWA" />
            </nuxt-link>

            <div v-if="isTopPage" class="hide-tab">
                <a href="#whatis"><span>AWAとは</span></a>
                <a href="#advantages"><span>特長</span></a>
                <a href="#support"><span>サポート</span></a>
                <a href="#faq"><span>よくある質問</span></a>
                <a href="#services"><span>サービス一覧</span></a>
            </div>
            <a v-if="isTopPage" href="#documents" class="link wide yellow">資料ダウンロード</a>
            <nuxt-link v-show="!isInquiryPage" class="link wide red" :to="inquiryLink">お問い合わせ</nuxt-link>
        </nav>
    </header>
</template>

<script>
export default {
    props: {
        inquiryLink: { type: String, required: true },
    },
    computed: {
        isTopPage() {
            return this.$route.name === 'awa';
        },
        isInquiryPage() {
            // 末尾にスラッシュがあれば削除
            const path = this.$route.path.replace(/\/$/, '');
            return path === this.inquiryLink;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/personal/base.scss';
.top-header {
    position: sticky;
    z-index: 999;
    width: 100%;
    height: var(--header-height);
    top: 0;
    background: rgba(248, 248, 248, 0.96);

    nav {
        width: 100%;
        height: 100%;
        display: flex;
    }

    .logo-area {
        height: 100%;
        margin-right: auto;
        padding: 0 1vw;
        display: flex;
        align-items: center;
        cursor: pointer;

        @include wide {
            padding: 0 10px;
        }

        @include sp {
            padding: 0 3vw;
        }

        img {
            vertical-align: middle;
            width: max(100px, 7.5vw);

            @include wide {
                max-width: 120px;
            }

            @include sp {
                width: 19vw;
            }
        }
    }

    .hide-tab {
        --spacing: clamp(1em, 1.5vw, 3em);
        display: contents;
        @media (max-width: 960px) {
            display: none;
        }

        > a {
            padding: 0 var(--spacing);

            &:last-of-type {
                padding-right: calc(var(--spacing) * 2);
            }

            > span {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background: #ffd753;
                    transform: scaleX(0);
                    transform-origin: left;
                    transition: transform 0.2s ease-out;
                }
            }
            &:hover > span::before {
                transform: scaleX(1);
            }
        }
    }

    .hide-tab > a,
    .link {
        height: 100%;
        display: inline-grid;
        place-items: center;
        color: #383e44;
        font-weight: bold;
        opacity: 1 !important;
    }

    .wide {
        width: clamp(10em, 27vw, 240px);
        transition: background-color 0.3s;
    }
    .yellow {
        background: #f6c900;
        &:hover {
            background: lighten(#f6c900, 20);
        }
    }
    .red {
        color: #fff;
        background: $ACCENT_COLOR;
        &:hover {
            background: darken($ACCENT_COLOR, 20);
        }
    }
}
</style>
