<template>
    <div>
        <nav v-if="!isSpScreen" class="user-nav">
            <el-dropdown trigger="click">
                <div class="top">
                    <span class="email">{{ getCurrentUser.email }}</span>
                    <i class="el-icon-caret-bottom" />
                </div>
                <el-dropdown-menu slot="dropdown" class="header-pulldown">
                    <section v-for="(section, sectionIdx) in linkList" :key="sectionIdx" class="list-section">
                        <nuxt-link
                            v-for="(item, i) in section"
                            :key="item.link"
                            :to="item.link"
                            :class="{ restricted: item.restricted }"
                            :tag="item.restricted ? 'div' : undefined"
                            :event="item.restricted ? '' : undefined"
                            @mouseenter.native="onLinkHover($event, item)"
                        >
                            <el-dropdown-item :disabled="item.restricted" :divided="sectionIdx > 0 && i === 0">
                                {{ item.label }}
                            </el-dropdown-item>
                        </nuxt-link>
                    </section>

                    <a href="https://support.crypto-city.net/hc/ja" target="_one">
                        <el-dropdown-item divided>ヘルプ</el-dropdown-item>
                    </a>
                    <div @click="logout">
                        <el-dropdown-item>ログアウト</el-dropdown-item>
                    </div>

                    <el-dropdown-item v-if="isRegistrationApplied" class="registration-type">
                        有料会員
                    </el-dropdown-item>
                    <el-dropdown-item v-else-if="getIsBusiness" class="registration-type">
                        無料期間中
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </nav>
        <nav v-else class="user-nav--mobile">
            <el-dropdown trigger="click">
                <div class="user-nav__text">
                    <img src="~assets/images/more-icon.svg" alt="more" />
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="isRegistrationApplied" class="registration-type">
                        有料会員
                    </el-dropdown-item>
                    <el-dropdown-item v-else-if="getIsBusiness" class="registration-type">
                        無料期間中
                    </el-dropdown-item>
                    <el-dropdown-item>
                        {{ getCurrentUser.email }}
                    </el-dropdown-item>
                    <el-dropdown-item v-if="getIsCpta" divided>
                        顧客 : {{ getCurrentUser.customer_name }}
                    </el-dropdown-item>

                    <section v-for="(section, sectionIdx) in linkList" :key="sectionIdx">
                        <nuxt-link
                            v-for="(item, i) in section"
                            :key="item.link"
                            :to="item.link"
                            :class="{ restricted: item.restricted }"
                            :tag="item.restricted ? 'div' : undefined"
                            :event="item.restricted ? '' : undefined"
                            @mouseenter.native="onLinkHover($event, item)"
                        >
                            <el-dropdown-item :divided="sectionIdx > 0 && i === 0" :disabled="item.restricted">
                                {{ item.label }}
                            </el-dropdown-item>
                        </nuxt-link>
                    </section>

                    <a href="https://support.crypto-city.net/hc/ja" target="_one">
                        <el-dropdown-item divided>ヘルプ</el-dropdown-item>
                    </a>
                    <div @click="logout">
                        <el-dropdown-item>ログアウト</el-dropdown-item>
                    </div>
                </el-dropdown-menu>
            </el-dropdown>
        </nav>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getPulldownLinks } from './static.js';

export default {
    name: 'PulldownMenu',
    computed: {
        ...mapGetters([
            'getCurrentUser',
            'getCptaStatus',
            'getCptaOfficeStatus',
            'getAccountType',
            'getIsCpta',
            'getIsCorporate',
            'getIsBusiness',
            'getYear',
            'getPlan',
            'getCustomerUserId',
            'getIsCorporateDisplay',
            'isRestrictedUser',
            'isSpScreen',
        ]),
        corporatePaymentLink() {
            return this.getCptaOfficeStatus.registration_applied ? '/subscriptions/payments' : '/cpta/payment/apply';
        },
        userState() {
            return {
                corporatePaymentLink: this.corporatePaymentLink,
                isCorporate: this.getIsCorporateDisplay,
                usingCampaign: this.getPlan === 'usingCampaign',
                isCustomerSelected: !!this.getCustomerUserId,
                isForeverFreeUser: this.getCurrentUser.is_free,
                isRestrictedUser: this.isRestrictedUser,
            };
        },
        linkList() {
            return getPulldownLinks(this.getAccountType, this.getCptaStatus.role, this.userState);
        },
        isRegistrationApplied() {
            return this.getIsBusiness && this.getCptaOfficeStatus.registration_applied;
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('logout', this);
            this.$message.success('ログアウトしました');
        },
        async closeYear() {
            await this.$confirm(`${this.getYear}年度の損益を確定しますか?`, '損益確定', {
                confirmButtonText: '確定する',
                cancelButtonText: 'キャンセル',
                dangerouslyUseHTMLString: true,
                type: 'warning',
            });
            const isSuccess = await this.$store.dispatch('closeYear');
            if (isSuccess) {
                this.$message({
                    type: 'success',
                    message: `${this.getYear}年の損益を確定しました`,
                });
            }
        },
        async openYear() {
            await this.$confirm(`${this.getYear}年度の損益の確定を解除しますか?`, '損益確定の解除', {
                confirmButtonText: '解除する',
                cancelButtonText: 'キャンセル',
                dangerouslyUseHTMLString: true,
                type: 'warning',
            });
            const isSuccess = await this.$store.dispatch('openYear');
            if (isSuccess) {
                this.$message({
                    type: 'success',
                    message: `${this.getYear}年の損益の確定を解除しました`,
                });
            }
        },
        async onLinkHover(event, item) {
            if (!item.restricted) return;
            this.$store.commit('showProhibitedTooltip', { event, position: 'left' });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/foundation/_mixin.scss';
@import '@/assets/scss/variables';

.registration-type {
    color: #ef2626 !important;
}

.user-nav {
    padding: 10px 0;

    .top {
        max-width: 16rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        cursor: pointer;
    }
    .email {
        font-size: 1.2rem;
        line-height: 1.4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.user-nav--mobile {
    padding: 10px 0;

    .user-nav__text {
        font-size: 1.2rem;
        cursor: pointer;
    }
}

.list-section {
    width: min(220px, 100vw);

    .restricted {
        * {
            color: #999 !important;
        }
        cursor: not-allowed;
    }
}
.header-pulldown {
    padding: 10px 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);

    ::v-deep .el-dropdown-menu__item {
        padding: 0 6px;
        color: #333;
        line-height: 2;
    }
}
</style>
