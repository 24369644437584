// @ts-check
import Cookies from 'js-cookie';

import { newsUrl } from '@/libs/const.js';
import { TradeValidator } from '@/libs/validators/index.js';
import { deserializeOrderTypes, createJournalSchemaMap, deserializeServiceNotice } from '@/libs/adapters/index.js';
import { setSentryUser, clearSentryUser } from '@/libs/reporter.js';
import { clientStorage, STORAGE_KEYS } from '@/libs/storage.js';
import { parseRSS, initGetRequest, getErrorMessage } from '@/libs/vuex.js';

export default {
    async initServiceNotifications({ dispatch }) {
        dispatch('loadServiceNotice');
        dispatch('loadNewsFeed');
    },
    async loadServiceNotice({ commit }) {
        const response = await this.$axios.get('/news');

        if (response?.status === 200) {
            commit('setServiceNotice', deserializeServiceNotice(response.data));
        }
    },
    async loadNewsFeed({ commit }) {
        const response = await fetch(`${newsUrl}/feed`);
        if (!response.ok) return;
        const news = parseRSS(await response.text());
        commit('setNews', news);
    },
    async authenticate({ getters, commit, dispatch }, { app, token: _token, is_force_login }) {
        // 違うlayoutを行き来した時にログイン状態にも関わらず再度呼ばれることがある。既にログイン済みの場合は何もしない
        if (getters.isLoggedIn) return true;

        if (_token) clientStorage.set(STORAGE_KEYS.accessToken, _token);
        const token = _token ?? clientStorage.get(STORAGE_KEYS.accessToken);
        if (!token) throw new Error('Token not found');

        if (typeof is_force_login === 'boolean') {
            clientStorage.set(STORAGE_KEYS.isForceLogin, is_force_login);
        }

        commit('setHasToken');

        app.$axios.setToken(token, 'Bearer');
        await dispatch('fetchSelf');
        commit('setServiceName', getters.getIsDapps);

        app.$gtm.push({ user_id: getters.getCurrentUser.id });
        setSentryUser(getters.getCurrentUser);

        return true;
    },
    logout({ commit, dispatch }, app) {
        app.$axios.setToken('');
        clientStorage.remove(STORAGE_KEYS.accessToken);
        clientStorage.remove(STORAGE_KEYS.isForceLogin);
        app.$gtm.push({ user_id: null });
        clearSentryUser();

        commit('resetState');
        dispatch('initServiceNotifications');

        app.$router.replace('/');
    },
    initAppData({ dispatch, commit, getters }) {
        clientStorage.cleanUp();

        // 画面表示前に取得したいデータ。
        const requiredImmediately = [dispatch('initMetadata')];
        if (getters.getIsDapps) requiredImmediately.push(dispatch('initDappsMetadata'));
        const required = Promise.all(requiredImmediately);

        // 画面表示後の取得でもOKなデータ
        const deferred = Promise.all([
            dispatch('initDeferredMetadata'),
            dispatch('loadPeriods'),
            dispatch('retrieveLocalStorage'),
        ]);

        commit('setIsInitialized');
        return { required, deferred };
    },
    apiRequest({ dispatch }, order) {
        return this.$axios(order)
            .then((response) => {
                return response;
            })
            .catch(async (ex) => {
                const isBlob = ex.response.data instanceof Blob;
                if (isBlob) {
                    const blob = ex.response.data;
                    const text = blob ? await blob.text() : null;
                    const [message] = text ? Object.values(JSON.parse(text)) : ['原因不明のエラーが発生しました'];
                    ex.response.data = { title: message };
                    dispatch('setErrorMessage', ex.response);
                    return ex.response;
                }
                /** ユーザー側でタブを閉じるなどして、リクエストがキャンセルされた場合はresponseがundefinedになることがある。
                他の箇所でエラーハンドリングしやすいように、ここで確実にstatusが入ったobjectが返るようにしておく。 */
                const response = ex.response?.status != null ? ex.response : { status: 0 };
                dispatch('setErrorMessage', response);
                return response;
            });
    },
    setErrorMessage({ commit }, response) {
        const message = getErrorMessage(response);
        commit('setErrorMessage', { type: 'error', message });
    },
    retrieveLocalStorage({ dispatch }) {
        dispatch('retrieveTutorialState');
        dispatch('retrieveLastViewedNewsDate');
    },
    retrieveLastViewedNewsDate({ commit }) {
        const saved = clientStorage.get(STORAGE_KEYS.latestPubDateOfViewedNews);
        if (saved) commit('setLastViewedNewsDate', saved);
    },
    updateLastViewedNewsDate({ getters, commit }) {
        const latestNewsDate = getters.getLatestNewsDate;
        if (latestNewsDate === getters.getLastViewNewsDate) return;

        commit('setLastViewedNewsDate', latestNewsDate);
        clientStorage.set(STORAGE_KEYS.latestPubDateOfViewedNews, latestNewsDate);
    },
    retrieveTutorialState({ commit }) {
        const saved = clientStorage.get(STORAGE_KEYS.tutorials);
        const state = saved ?? { autoRegistration: false };
        commit('setTutorialState', state);
    },
    updateTutorialState({ state, commit }, _updated) {
        const updated = { ...state.tutorials, ..._updated };
        commit('setTutorialState', updated);
        clientStorage.set(STORAGE_KEYS.tutorials, updated);
    },
    async fetchSelf({ commit }) {
        const response = await this.$axios.get('/user/self');

        Cookies.set('cookie_params', response.data.cookie_params);
        commit('setCurrentUser', response.data);
    },
    async loadPeriods({ dispatch, commit, getters }) {
        const params =
            getters.getIsCpta && getters.getCustomerUserId !== null ? { user_id: getters.getCustomerUserId } : {};

        commit('setCalcStateProcessing');
        const response = await Promise.all([
            dispatch('apiRequest', { url: '/user/users_year', method: 'get', params }),
            dispatch('apiRequest', { url: '/user/latest_closed_term', method: 'get' }),
            dispatch('apiRequest', { url: 'unimported_trades/get_count', method: 'get' }),
            dispatch('apiRequest', { url: 'pending_transactions/get_count', method: 'get' }),
            dispatch('apiRequest', { url: 'defi_transaction/get_count', method: 'get' }),
        ]);

        if (response.some(({ status }) => status !== 200)) {
            return;
        }

        const { data: usersYear } = response[0];
        const { data: closedTerm } = response[1];
        const { data: unimportedCount } = response[2];
        const { data: pendingTradeCount } = response[3];
        const { data: pendingDefiCount } = response[4];

        commit('setUnimportedTradeCount', unimportedCount);
        commit('setPendingTradeCount', pendingTradeCount);
        commit('setPendingDefiCount', pendingDefiCount);

        // 取引の登録が0
        if (!usersYear.latest) {
            commit('initPeriod', { usersYear, isBusiness: getters.getIsBusiness });
            commit('setCalcStateUnexecuted');
            commit('setZeroTradeRecord', true);
            return;
        }

        commit('setPeriodLoaded', true);
        commit('setAccountingMonth', usersYear.accounting_month);
        commit('setClosingTerm', usersYear.closing_term);
        commit('setIsCloseValuate', !!usersYear.is_close_valuate);
        commit('setLatestClosedTerm', closedTerm);
        commit('setZeroTradeRecord', false);
        commit('setRangeOfPeriod', {
            latest: usersYear.latest,
            oldest: usersYear.oldest,
            oldestOpen: usersYear.oldest_open,
            max_reachable_year: usersYear.max_reachable_year,
        });

        if (getters.getYear > getters.getLatestYear || getters.getYear < getters.getOldestYear) {
            commit('setYear', usersYear.latest);
        }
        dispatch('triggerCheckCalcStatus');
        dispatch('loadClosingStatus');
        // Dapps版のみ、未仕訳処理数を取得
        if (getters.getIsDapps) {
            dispatch('loadTxConfirmationCount');
        }
    },
    retrieveCustomerId({ getters, dispatch }) {
        const session = clientStorage.get(STORAGE_KEYS.cptaSession);
        if (!session) throw new Error('There is no ongoing session');
        if (session.userId !== getters.getCurrentUser.id) throw new Error('Login user changed');
        return dispatch('initCustomerData', session.customerId);
    },
    selectCustomerId({ getters, dispatch }, customerId) {
        clientStorage.set(STORAGE_KEYS.cptaSession, {
            userId: getters.getCurrentUser.id,
            customerId,
        });
        return dispatch('initCustomerData', customerId);
    },
    async initCustomerData({ commit, dispatch }, customerId) {
        commit('setCustomerUserId', customerId);
        const { required, deferred } = await dispatch('initAppData');
        return Promise.all([dispatch('fetchSelf'), required, deferred]);
    },
    async initCptaUser({ dispatch }) {
        dispatch('clearUserRelatedData');
        await dispatch('fetchSelf');
    },
    clearUserRelatedData({ commit }) {
        commit('resetUserState');
        clientStorage.remove(STORAGE_KEYS.cptaSession);
    },
    async changeUserCalcType({ dispatch, commit, getters }, calcType) {
        const response = await dispatch('apiRequest', {
            url: '/user/change_calc_type',
            method: 'post',
            data: {
                calc_type: calcType,
                year: getters.getYear,
            },
        });
        if (response.status === 200) {
            commit('setUserCalcType', calcType);
            dispatch('triggerCheckCalcStatus');
        }
    },
    async advanceYear({ dispatch, commit, getters }) {
        if (getters.getYear !== getters.getMaxReachableYear) {
            commit('setYear', getters.getYear + 1);
            dispatch('loadClosingStatus');
            dispatch('triggerCheckCalcStatus');
        }
    },
    async backYear({ dispatch, commit, getters }) {
        if (getters.getYear !== getters.getOldestYear) {
            commit('setYear', getters.getYear - 1);
            dispatch('loadClosingStatus');
            dispatch('triggerCheckCalcStatus');
        }
    },
    async loadClosingStatus({ dispatch, commit, getters }) {
        const response = await dispatch('apiRequest', {
            url: 'final_balances/closing_status',
            method: 'get',
            params: {
                year: getters.getYear,
            },
        });
        if (response) {
            commit('setClosingStatus', response.data);
        }
    },
    async closeYear({ dispatch, commit, getters }) {
        const response = await dispatch('apiRequest', {
            url: 'final_balances/close',
            method: 'post',
            data: {
                year: getters.getYear,
            },
        });
        if (response) {
            commit('setClosingStatus', 'closed');
            return true;
        }
        return false;
    },
    async openYear({ dispatch, commit, getters }) {
        const response = await dispatch('apiRequest', {
            url: 'final_balances/open',
            method: 'post',
            data: {
                year: getters.getYear,
            },
        });
        if (response) {
            commit('setClosingStatus', 'closable');
            return true;
        }
        return false;
    },
    // checkCalcStatusループ（polling）を発火する
    // フラグをチェックして、すでにループが実行されている場合は発火しない
    async triggerCheckCalcStatus({ dispatch, getters }) {
        if (getters.isCheckCalcStateRunning) {
            return;
        }
        dispatch('checkCalcStatus');
    },
    // checkCalcStatusループ
    async checkCalcStatus({ dispatch, commit, getters }) {
        // ループ実行中フラグを立てる
        commit('setCheckCalcStateRunning', true);
        commit('setCalcStateProcessing');
        const year = getters.getYear;
        const calcType = getters.getUserCalcType;
        const { data: response } = await dispatch('apiRequest', {
            url: '/user/calculation_status',
            method: 'get',
            params: { year },
        });
        commit('setCalcState', response.result);
        // レスポンスが無い or まだ計算が実行中 or リクエスト中に選択年度、計算方法が変更になった場合、もう一度リクエストする
        if (
            !response ||
            response.result.status === 'processing' ||
            year !== getters.getYear ||
            calcType !== getters.getUserCalcType
        ) {
            setTimeout(() => {
                dispatch('checkCalcStatus');
            }, 2000);
        } else {
            // 計算が正常終了、またはエラー終了したらcheckCalcStatusのポーリングを終了してloadMinusBalanceを実行する
            // ループ実行中フラグをおろす
            // 計算方法をレスポンスの結果に更新する
            commit('setCheckCalcStateRunning', false);
            commit('setUserCalcType', response.result.calc_type);
            dispatch('loadMinusBalance');
        }
    },
    async loadMinusBalance({ dispatch, commit, getters }) {
        // 移動平均&&計算成功パターンならminusBalanceは空配列
        if (getters.getUserCalcType === 'moving_average' && getters.getCalcStatus === 'completed') {
            commit('setMinusBalance', []);
            return;
        }
        // 移動平均&&計算成功以外のパターンでminusBalanceをgetする
        const { data: response } = await dispatch('apiRequest', {
            url: '/trades/minus_balance',
            method: 'get',
        });
        commit('setMinusBalance', response);
    },
    async initExchangerUsage({ commit, dispatch }) {
        const { data } = await dispatch('apiRequest', { url: '/exchangers/usage', method: 'get' });
        commit('setExchangerUsage', data);
    },
    updateExchangerUsage({ getters, commit }, updateFn) {
        commit('setExchangerUsage', updateFn(getters.getExchangerUsage));
    },
    async loadTxConfirmationCount({ commit, dispatch }) {
        const { data: txConfirmation } = await dispatch('apiRequest', {
            url: 'tx_confirmations/get_count',
            method: 'get',
        });
        if (txConfirmation) {
            commit('setTxConfirmationCount', txConfirmation);
        }
    },
    /** 全版で認証後画面表示前に取得するデータ */
    async initMetadata({ commit, dispatch }) {
        const get = initGetRequest(dispatch);

        const [{ data: validateConfig }, { data: exchangers }, { data: rawOrderTypes }, { data: exchangerUsage }] =
            await Promise.all([
                get('/unimported_trades/validate_config'),
                get('/exchangers'),
                get('/order_type_info'),
                get('/exchangers/usage'),
            ]);

        const validator = new TradeValidator(validateConfig);
        const deserializedOT = deserializeOrderTypes(rawOrderTypes);

        commit('setTradeValidator', validator);
        commit('setExchangers', exchangers);
        commit('setOrderTypes', deserializedOT);
        commit('setExchangerUsage', exchangerUsage);
    },
    /** Dapps版のみ認証後画面表示前に取得するデータ */
    async initDappsMetadata({ commit, dispatch }) {
        const get = initGetRequest(dispatch);

        const [
            { data: journalSchema },
            { data: journalRuleSchema },
            { data: accountingRule },
            { data: accountingTransfer },
        ] = await Promise.all([
            get('/credit_debit_info'),
            get('/credit_debit_info/journal_rule'),
            get('/user/accounting_rule'),
            get('/accounting_transfer/get_count'),
        ]);

        const journalSchemaMap = createJournalSchemaMap(journalSchema);
        const journalRuleSchemaMap = createJournalSchemaMap(journalRuleSchema);

        commit('setJournalSchemaMap', journalSchemaMap);
        commit('setJournalRuleSchemaMap', journalRuleSchemaMap);
        commit('setAccountingSoftware', accountingRule.accounting_software);
        commit('setAccountingTransferCount', accountingTransfer);
    },
    /** 全版で認証後取得するが、画面の表示後でもいいデータ */
    async initDeferredMetadata({ commit, dispatch }) {
        const get = initGetRequest(dispatch);

        const { data: fileAlertsResponse } = await get('/file_alerts');

        commit('setFileAlerts', fileAlertsResponse.alert);
    },
};
