<template>
    <div class="awalp">
        <AWAHeader :inquiryLink="inquiryLink" />
        <EnvBar class="env" />
        <nuxt-child class="content" :inquiryLink="inquiryLink" />
        <LPFooter />
    </div>
</template>
<script>
import EnvBar from '@/components/layout/EnvBar.vue';
import AWAHeader from '@/components/pages/awa/LPHeader.vue';
import LPFooter from '@/components/pages/awa/LPFooter.vue';

export default {
    components: {
        EnvBar,
        AWAHeader,
        LPFooter,
    },
    data() {
        return {
            pageTitle:
                'Web3事業者向け経理サポートツール「Aerial Web3 Accounting」 | 暗号資産やNFTの会計処理をおまかせ!',
            pageDescription:
                'Web3領域では避けられない仮想通貨(暗号資産)やNFTの会計処理にかかる作業時間を削減できる経理用ビジネスツール「Aerial Web3 Accounting(AWA)」は、ブロックチェーンの仕組みを知らなくても簡単に使えます。',
            pageOgImage: 'https://crypto-city.net/png/awa-ogp.png',
            inquiryLink: '/awa/inquiry',
        };
    },
    head() {
        return {
            title: this.pageTitle,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.pageDescription,
                },
                // 現時点ではSPAなので下記ogタグは効果がないが、置いてはおく
                {
                    property: 'og:title',
                    hid: 'og:title',
                    content: this.pageTitle,
                },
                { property: 'og:description', hid: 'og:description', content: this.pageDescription },
                { property: 'og:url', hid: 'og:url', content: 'https://crypto-city.net/awa' },
                { property: 'og:image', hid: 'og:image', content: this.pageOgImage },
                { property: 'twitter:image', hid: 'twitter:image', content: this.pageOgImage },
                {
                    property: 'og:site_name',
                    hid: 'og:site_name',
                    content: this.pageTitle,
                },
            ],
            link: [
                { rel: 'canonical', hid: 'canonical', href: 'https://crypto-city.net/awa' },
                { rel: 'icon', hid: 'favicon-32', type: 'image/png', sizes: '32*32', href: '/awa-favicon-32.png' },
                { rel: 'stylesheet', href: '/css/reset.css' },
                { rel: 'stylesheet', href: '/css/normalize.css' },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap',
                },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/awa/base';

.awalp {
    --header-height: 60px;

    --txt-clr-base: #383e44;

    --fz12: clamp(11px, 0.96vw, 12px);
    --fz14: clamp(12px, 1.12vw, 14px);
    --fz16: clamp(14px, 1.28vw, 16px);
    --fz18: clamp(15px, 1.38vw, 18px);
    --fz20: clamp(16px, 1.48vw, 20px);
    --fz22: clamp(18px, 1.64vw, 22px);
    --fz24: clamp(19px, 1.86vw, 24px);
    --fz32: clamp(21px, 2.2vw, 32px);
    --fz40: clamp(26px, 2.9vw, 40px);
    --fz48: clamp(25px, 3.3vw, 48px);
    --fz62: clamp(32px, 3.9vw, 62px);

    @include sp {
        --fz12: clamp(11px, 2.4vw, 12px);
        --fz14: clamp(12px, 2.8vw, 14px);
        --fz16: clamp(14px, 3.2vw, 16px);
        --fz18: clamp(15px, 3.6vw, 18px);
        --fz20: clamp(16px, 3.8vw, 20px);
        --fz22: clamp(18px, 4.6vw, 22px);
        --fz24: clamp(19px, 4.9vw, 24px);
        --fz32: clamp(21px, 5.5vw, 32px);
        --fz40: clamp(26px, 6.4vw, 40px);
        --fz48: min(9.4vw, 5.5vh, 46px);
        --fz62: clamp(30px, 7.8vw, 62px);
    }

    width: 100vw;
    font-family: 'Noto Sans JP', sans-serif;
    color: var(--txt-clr-base);
    line-height: 1.5;
    font-size: var(--fz16);
}

*::selection {
    background: #065897;
    color: #fff;
}

*::-moz-selection {
    background: #065897;
    color: #fff;
}

.env {
    position: fixed;
    top: 68px;
    width: 100%;
    z-index: 10;
}
</style>
